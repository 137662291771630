<template>
  <fragment>
    <app-header :title="'Screen - ' + screen.name" />

    <c-horizontal-nav>
      <c-horizontal-nav-link :to="{ name: 'screens.single' }" exact>
        Overview
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'screens.single.image-history' }" exact>
        Image History
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'screens.single.faults' }" exact>
        Fault History
      </c-horizontal-nav-link>
      <c-horizontal-nav-link :to="{ name: 'screens.single.brightness-logs' }" exact>
        Brightness Logs
      </c-horizontal-nav-link>
    </c-horizontal-nav>

    <router-view v-if="screen.id" />
  </fragment>
</template>

<script>
import { handleServerValidation } from '@/utils'
import { helperComputed, helperMethods } from '@/vuex/modules/screens'

export default {
  props: {
    screenId: {
      type: Number,
      required: true
    }
  },

  async created () {
    try {
      await this.getScreen(this.screenId)
    } catch (error) {
      handleServerValidation(error, this.$refs.validation)
    }
  },

  computed: {
    ...helperComputed,
  },

  methods: {
    ...helperMethods
  }
}
</script>
