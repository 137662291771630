<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>

      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-12 col-lg-4 col-xl-4">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <div class="mb-3">
          <label>Media Owner</label>

          <media-owner-select v-model="filters.mediaOwner" prependAll/>
        </div>
      </div>
      <div class="col-12 col-lg-4 col-xl-4">
        <div class="mb-3">
          <label>Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :searchable="false"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          >
          </c-input>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label>Tag</label>

          <screen-tag-select holder="All" v-model="selectedTags" @input="updateTags"/>
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'
import ScreenTagSelect from '@/components/forms/ScreenTagSelect'
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    MediaOwnerSelect,
    ScreenTagSelect
  },

  data () {
    return {
      filters: {
        search: null,
        status: 'all',
        mediaOwner: null,
        tags: []
      },
      statusOptions: [
        { name: 'All', value: 'all' },
        { name: 'No Issues', value: 1 },
        { name: 'Communication Error', value: 2 },
        { name: 'Fault', value: 4 }
      ],
      selectedTags: []
    }
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },
  },

  methods: {
    updateSearch: debounce(function (value) {
      this.filters.search = value
    }, 500),

    updateTags () {
      this.filters.tags = this.selectedTags.map(({ id }) => id)
    }
  }
}
</script>
