<template>
  <li class="col-12 col-sm-6 col-lg-4 col-xxl-3">
    <a
      @click="$emit('selected', item)"
      class="card"
      ><img
        :src="item.image_url"
        width="16"
        height="9"
        alt=""
        class="screen-thumbnail"
      />
      <div class="card-body">{{ item.taken }}</div></a
    >
  </li>
</template>

<script>
export default {
  props: {
    item: Object
  }
}
</script>
