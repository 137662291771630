import Admin from './AdminScreensList.vue'
import User from './../media-owners/MediaOwnersScreensList.vue'

const components = {
  admin: Admin,
  user: User,
}

export default {
  functional: true,

  render (h, context) {
    const { role } = context.parent.$store.state.auth.user

    return h(components[role.name], context.data, context.children)
  }
}
