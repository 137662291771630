<template>
  <fragment>
    <app-header title="Screens">
    </app-header>

    <div class="container">
      <screens-list-filters v-model="params" />

      <c-data-list :items="screens" @load="load" class="list-data-screens">
        <template #columns>
          <div class="col-xl-3">Name</div>
          <div class="col-xl-3 flex-grow-1">Tags</div>
          <div class="col-xl-2">Media Owner</div>
          <div class="col-xl-2">Current Brightness</div>
          <div class="col-xl-2">Ambient Brightness</div>
        </template>

        <template #default="{ item }">
          <screens-list-item :screen="item" />
        </template>
      </c-data-list>
    </div>
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/screens'
import { compareQueryParams } from '@codium/codium-vue'
import ScreensListItem from './ScreensListItem'
import ScreensListFilters from './ScreensListFilters'

export default {
  components: {
    ScreensListItem,
    ScreensListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getScreens({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed
  },

  beforeDestroy () {
    this.resetScreens()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getScreens({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
