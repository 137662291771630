<template>
  <div class="container container-fluid mt-4 mb-4">

    <faults-list-filters v-model="params" />

    <c-data-list :items="faults" @load="load" class="list-data no-links list-data-xs-full">
      <template #columns>
        <div class="col-lg-3">Start Time</div>
        <div class="col-lg-3">End Time</div>
        <div class="col-lg-2">Duration</div>
        <div class="col-lg-4">Type</div>
      </template>

      <template #default="{ item }">
        <faults-list-item :fault="item" />
      </template>
    </c-data-list>
  </div>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/screens/faults'
import { compareQueryParams } from '@codium/codium-vue'
import FaultsListItem from './FaultsListItem'
import FaultsListFilters from './FaultsListFilters'

export default {
  components: {
    FaultsListItem,
    FaultsListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getFaults({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed
  },

  beforeDestroy () {
    this.resetFaults()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getFaults({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
