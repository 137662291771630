<template>
  <div class="container">

    <list-filters v-model="params" />

    <c-infinite-scroll
      @load="load"
      scroll-target="#page-content-wrapper"
    >
      <ul class="list-data row gx-3">
        <image-history-list-item v-for="item in images" :key="item.id" :item="item" @selected="updateLightboxItem" />
        <li v-if="!hasItems" class="py-4">
          <p class="text-center">No items to show.</p>
        </li>
      </ul>
    </c-infinite-scroll>

    <camera-wall-image-drawer v-if="lightboxItem" :item="lightboxItem" ref="lightbox"/>
  </div>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/screens/images'
import { compareQueryParams } from '@codium/codium-vue'
import ImageHistoryListItem from './ImageHistoryListItem'
import ListFilters from './../faults/FaultsListFilters'
import CameraWallImageDrawer from '@/views/camera-wall/CameraWallImageDrawer.vue'

export default {
  components: {
    ImageHistoryListItem,
    ListFilters,
    CameraWallImageDrawer
  },

  data () {
    return {
      params: null,
      lightboxItem: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getImages({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed,

    hasItems () {
      return this.images.length > 0
    },
  },

  beforeDestroy () {
    this.resetImages()
  },

  methods: {
    ...helperMethods,

    updateLightboxItem (item) {
      this.lightboxItem = item
      this.$nextTick(() => {
        this.$refs.lightbox.open()
      })
    },

    async load (done) {
      const { params } = this

      try {
        await this.getImages({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
