<template>
  <router-link tag="a" :to="{ name: 'screens.single', params: { screenId: screen.id } }">
    <div class="row">
      <div class="col-12 col-xl-3">
        <div class="row">
          <div class="col d-flex align-items-center">
            <p class="text-truncate">{{ screen.name }}</p>
          </div>
        </div><!-- /.row -->
      </div>
      <div class="col-12 col-xl-3 flex-grow-1">
        <div>
          <div v-for="tag in screen.tags" :key="tag.id" class="chip chip-primary">
            {{ tag.name }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <p class="text-truncate">
          <span class="data-label d-lg-none">Media Owner</span>
          {{ screen.media_owner ? screen.media_owner.name : '-' }}
        </p>
      </div>
      <div class="col-6 col-md-4 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Current Brightness</span>
          {{ screen.brightness }} ({{ screen.brightness_percentage }}%)
        </p>
      </div>
      <div class="col-6 col-md-4 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Ambient Brightness</span>
          {{ screen.environment_brightness }}
        </p>
      </div>
    </div>
    <i
        data-bs-toggle="tooltip"
        class="indicator far fa-fw"
        :class="indicatorClass()"
        :data-bs-original-title="screen.status"
        :aria-label="screen.status"
    ></i>
  </router-link>
</template>

<script>
export default {
  props: {
    screen: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      indicatorClasses: {
        'No Issues': 'fa-check text-success',
        'Communication Error': 'fa-wifi-slash text-warning',
        Fault: 'fa-exclamation-triangle text-danger'
      }
    }
  },

  methods: {
    indicatorClass () {
      return this.indicatorClasses[this.screen.status]
    },
  }
}
</script>
