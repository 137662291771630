<template>
  <div>
    <div>
      <button type="button" data-bs-toggle="collapse" :data-bs-target="`#${fault.type}-${fault.id}-item`" aria-expanded="false" :aria-controls="`${fault.type}-${fault.id}-item`" class="accordion-button collapsed" style="background-color: #fff;">
          <div class="row flex-grow-1">
            <div class="col-6 col-lg-3">
              {{ fault.start_time }}
            </div>
            <div class="col-6 col-lg-3">
              {{ fault.end_time }}
            </div>
            <div class="col-6 col-lg-2">
              {{ fault.duration }}
            </div>
            <div class="col-6 col-lg-4">
              <i
                data-bs-toggle="tooltip"
                class="indicator far fa-fw me-3"
                :class="indicatorClass()"
              ></i>
              {{ fault.type }}
            </div>
          </div>
      </button>
    </div>
    <div :id="`${fault.type}-${fault.id}-item`" :aria-labelledby="`${fault.type}-${fault.id}-heading`" class="accordion-collapse collapse" style="" >
      <div class="accordion-body" v-if="fault.fault_details">
        <strong>{{ formatTitle(fault.fault_details.reason) }}:</strong><br />
        {{ faultMessages[fault.fault_details.reason].replace("{fault_count}", fault.fault_details.fault_count).replace("{panels}", fault.fault_details.panels).replace("{panel_count}", fault.fault_details.panels > 1 ? 'panels' : 'panel') }}
      </div>
      <div class="accordion-body" v-else>
        <strong>No Data Available</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    fault: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      indicatorClasses: {
        Alarm: 'fa-exclamation-triangle text-danger',
        Fault: 'fa-exclamation-triangle text-danger',
      },
      faultMessages: {
        monitorCardVoltage: this.fault.fault_details?.fault_count > 1 ? 'Power supply fault causing {fault_count} parts of {panels} {panel_count} to display black.' : 'Power supply fault causing part of {panels} {panel_count} to display black.',
        smartModuleVoltage: 'Power supply fault causing part of {panels} {panel_count} to display black.',
        sendingCardDviStatus: 'Screen black due to no signal from media player.',
        sendingCardWorkStatus: 'Screen black due to sender card failure.',
        receivingCardWorkStatus: '{fault_count} {panel_count} displaying black.',
        monitorCardWorkStatus: 'Power supply fault causing part of {panels} {panel_count} to display black.'
      }
    }
  },

  methods: {
    indicatorClass () {
      return this.indicatorClasses[this.fault.type]
    },
    formatTitle (word) {
      const words = word.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')
      return words.map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
    },
  },
}
</script>
