<template>
  <c-data-filters>
    <div class="row">
      <div class="col">
        <div class="row flex-grow-1">
          <div class="col-lg-6 mb-3">
            <label>Date From</label>

            <c-date ref="dateFrom" v-model="filters.dateFrom" placeholder=""/>
          </div>

          <div class="col-lg-6 mb-3">
            <label>Time From</label>

            <flatpickr
              v-model="filters.timeFrom"
              :value="filters.timeFrom"
              :config="timeConfig"
              data-field="timeFrom"
              :disabled="!filters.dateFrom"
              class="form-control time-single"
            />
          </div>
        </div>
      </div>

      <div class="col-auto d-flex align-items-center">
        <p class="form-control-plaintext text-muted mt-2 px-2">-</p>
      </div>

      <div class="col">
        <div class="row flex-grow-1">
          <validation-provider tag="div" class="col-lg-6 mb-3" name="date to" vid="dateTo" :rules="{ date_after: filters.dateFrom }" v-slot="{ failed }">
            <label>Date To</label>

            <c-date v-model="filters.dateTo" placeholder="" :class="{ 'is-invalid': failed }"/>
            <c-help-block/>
          </validation-provider>

          <validation-provider tag="div" class="col-lg-6 mb-3" name="time to" vid="timeTo" rules="" v-slot="{ failed }">
            <label>Time To</label>

            <flatpickr
              v-model="filters.timeTo"
              :value="filters.timeTo"
              :config="timeConfig"
              data-field="timeTo"
              :disabled="!filters.dateTo"
              class="form-control time-single"
              :class="{ 'is-invalid': failed }"
            />
            <c-help-block/>
          </validation-provider>
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'
import dayjs from 'dayjs'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        dateFrom: null,
        timeFrom: null,
        dateTo: null,
        timeTo: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        allowInput: false,
        minuteIncrement: 1,
        onChange: (times, timeString, instance) => {
          const field = instance.element.dataset.field
          const formattedTime = dayjs(timeString, 'h:mm A').isValid() ? dayjs(timeString, 'h:mm A').format('HH:mm:ss') : null
          const value = { ...this.user, [field]: formattedTime }

          this.$emit('update', this.index, value)
        }
      },
    }
  },

  methods: {
    updateSearch: debounce(function (value) {
      this.filters.search = value
    }, 500),

    timeFromFocused () {
    }
  },
}
</script>
