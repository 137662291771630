<template>
  <div class="row">
    <div class="col-6 col-lg-4 col-xl-3">
      {{ log.date }}
    </div>
    <div class="col-6 col-lg-4 col-xl-3">
      {{ log.brightness_percentage }}%
    </div>
    <div class="col-6 col-lg-4 col-xl-3">
      {{ log.environment_brightness }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    log: {
      required: true,
      type: Object
    }
  }
}
</script>
