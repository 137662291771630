<template>
  <c-data-filters>
    <div class="row">
      <div class="col">
        <div class="row flex-grow-1">
          <div class="col-lg-6 mb-3">
            <label>Date From</label>

            <c-date ref="dateFrom" v-model="filters.dateFrom" placeholder=""/>
          </div>

          <div class="col-lg-6 mb-3">
            <label>Time From</label>

            <flatpickr
              v-model="filters.timeFrom"
              :value="filters.timeFrom"
              :config="timeConfig"
              data-field="timeFrom"
              :disabled="!filters.dateFrom"
              class="form-control time-single"
            />
          </div>
        </div>
      </div>

      <div class="col-auto d-flex align-items-center">
        <p class="form-control-plaintext text-muted mt-2 px-2">-</p>
      </div>

      <div class="col">
        <div class="row flex-grow-1">
          <validation-provider tag="div" class="col-lg-6 mb-3" name="date to" vid="dateTo" :rules="{ date_after: filters.dateFrom }" v-slot="{ failed }">
            <label>Date To</label>

            <c-date v-model="filters.dateTo" placeholder="" :class="{ 'is-invalid': failed }"/>
            <c-help-block/>
          </validation-provider>

          <validation-provider tag="div" class="col-lg-6 mb-3" name="time to" vid="timeTo" rules="" v-slot="{ failed }">
            <label>Time To</label>

            <flatpickr
              v-model="filters.timeTo"
              :value="filters.timeTo"
              :config="timeConfig"
              data-field="timeTo"
              :disabled="!filters.dateTo"
              class="form-control time-single"
              :class="{ 'is-invalid': failed }"
            />
            <c-help-block/>
          </validation-provider>
        </div>
      </div>

      <div class="col-12 col-xl-auto mb-3 text-end">
        <button type="button" @click="downloadLog('csv')" class="btn btn-outline-primary mt-xl-4">
          <i class="far fa-file-spreadsheet"></i> Download Log
        </button>
      </div>
      <div class="col-12 col-xl-auto mb-3 text-end">
        <button type="button" @click="downloadLog('pdf')" class="btn btn-outline-primary mt-xl-4">
          <i class="far fa-file-pdf"></i> Download PDF
        </button>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { get } from 'vuex-pathify'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import swal from 'sweetalert'
import { http } from '@/utils'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        dateFrom: null,
        timeFrom: null,
        dateTo: null,
        timeTo: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        allowInput: false,
        minuteIncrement: 1,
        onChange: (times, timeString, instance) => {
          const field = instance.element.dataset.field
          const formattedTime = dayjs(timeString, 'h:mm A').isValid() ? dayjs(timeString, 'h:mm A').format('HH:mm:ss') : null
          const value = { ...this.user, [field]: formattedTime }

          this.$emit('update', this.index, value)
        }
      },
    }
  },

  computed: {
    ...get('screens', ['screen']),
  },

  methods: {
    updateSearch: debounce(function (value) {
      this.filters.search = value
    }, 500),

    async downloadLog (type) {
      if (!this.filters.dateFrom || !this.filters.dateTo) {
        await swal({
          title: 'Invalid time range.',
          text: 'Please set a valid date range.',
          buttons: {
            confirm: { text: 'Close' }
          }
        })
        return
      }

      const params = {
        ...this.filters,
        type: type
      }
      return http
        .get(`reports/screens/${this.screen.id}/brightness-logs`, {
          responseType: 'blob',
          headers: { accept: 'application/json, text/csv' },
          params
        })
        .then(response => {
          const filename = `brightness-log-screen-${this.screen.id}-${dayjs().format('YYYY-MMMM-DD')}-${dayjs().format('HH-mm')}.${type}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          return response
        })
    },

    timeFromFocused () {
    }
  },
}
</script>
