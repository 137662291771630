<template>
  <c-input
    as="multiselect"
    :class="invalidClass"
    v-model="selected"
    placeholder="Select"
    :show-labels="false"
    :options="timezoneOptions"
    label="name"
    track-by="id"
  />
</template>

<script>
import { http } from '@/utils'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: String,
  },

  data () {
    return {
      timezoneOptions: []
    }
  },

  computed: {
    selected: {
      get () {
        return this.timezoneOptions.find(({ id }) => id === this.value)
      },

      set (option) {
        this.$emit('input', option ? option.id : null)
      }
    }
  },

  async created () {
    const response = await http.get('lists/timezones')
    this.timezoneOptions = response.data.map(timezone => ({
      id: timezone,
      name: timezone.replace(/_/g, ' ')
    }))
  }
}
</script>
