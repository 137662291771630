<template>
  <div class="container container-fluid mt-4 mb-4">

    <brightness-logs-list-filters v-model="params" />

    <c-data-list :items="brightnessLogs" @load="load" class="list-data no-links list-data-xs-full">
      <template #columns>
        <div class="col-6 col-lg-4 col-xl-3">Date and Time</div>
        <div class="col-3 col-lg-3 col-xl-3">Brightness</div>
        <div class="col-3 col-lg-3 col-xl-3">Ambient Brightness</div>
      </template>

      <template #default="{ item }">
        <brightness-logs-list-item :log="item" />
      </template>
    </c-data-list>
  </div>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/screens/brightness-logs'
import { compareQueryParams } from '@codium/codium-vue'
import BrightnessLogsListItem from './BrightnessLogsListItem'
import BrightnessLogsListFilters from './BrightnessLogsListFilters'

export default {
  components: {
    BrightnessLogsListItem,
    BrightnessLogsListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getBrightnessLogs({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed
  },

  beforeDestroy () {
    this.resetBrightnessLogs()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getBrightnessLogs({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
